var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: { inline: true },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.search($event)
            }
          }
        },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { placeholder: "疾病名称", clearable: "" },
                model: {
                  value: _vm.keywords,
                  callback: function($$v) {
                    _vm.keywords = $$v
                  },
                  expression: "keywords"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addOrUpdateHandle()
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              width: "50",
              align: "center",
              label: "序号"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              "header-align": "center",
              width: "200",
              align: "center",
              label: "名称"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              width: "150",
              label: "疾病图标"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.imgUrl
                      ? _c("img", {
                          staticStyle: { width: "60px", height: "60px" },
                          attrs: { src: scope.row.imgUrl }
                        })
                      : _vm._e()
                  }
                }
              ],
              null,
              true
            )
          }),
          _c("el-table-column", {
            attrs: {
              prop: "weight",
              "header-align": "center",
              align: "center",
              label: "权重"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(scope.row.weight ? scope.row.weight : 0))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              label: "是否首页展示"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                        "active-value": 1,
                        "inactive-value": 2,
                        "active-text":
                          scope.row.recommend === 1 ? "已展示" : "未展示"
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeRecommendStatus(
                            scope.row.id,
                            scope.row.recommend
                          )
                        }
                      },
                      model: {
                        value: scope.row.recommend,
                        callback: function($$v) {
                          _vm.$set(scope.row, "recommend", $$v)
                        },
                        expression: "scope.row.recommend"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "description",
              "header-align": "center",
              align: "center",
              label: "描述"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              width: "150",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.addOrUpdateHandle(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.deleteHandle(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { float: "right", "margin-top": "20px" } },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }