<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item>
				<el-input v-model="keywords" placeholder="疾病名称" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
				<el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column type="index" width="50" align="center" label="序号"> </el-table-column>
			<el-table-column prop="name" header-align="center" width="200" align="center" label="名称"></el-table-column>
			<el-table-column header-align="center" align="center" width="150" label="疾病图标">
				<img v-if="scope.row.imgUrl" slot-scope="scope" :src="scope.row.imgUrl" style="width: 60px;height: 60px;">
			</el-table-column>
			<el-table-column prop="weight" header-align="center" align="center" label="权重">
				<template slot-scope="scope">{{scope.row.weight ? scope.row.weight : 0}}</template>
			</el-table-column>
			<el-table-column header-align="center" align="center" label="是否首页展示">
				<template slot-scope="scope">
					<el-switch
						v-model="scope.row.recommend"
						@change="changeRecommendStatus(scope.row.id, scope.row.recommend)"
						active-color="#13ce66"
						inactive-color="#ff4949"
						:active-value="1"
						:inactive-value="2"
						:active-text="scope.row.recommend === 1 ? '已展示' : '未展示'"
					>
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column prop="description" header-align="center" align="center" label="描述"></el-table-column>
			<el-table-column header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
					<el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {
			keywords: '',
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0
		};
	},
	created () {
		this.search();
	},
	methods: {
		search () {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			this.$root.request('diseaseList', {
				keywords: this.keywords,
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows;
				}
			});
		},
		addOrUpdateHandle (id) {
			this.$root.go('/mnt/addOrUpdateDisease', { id });
		},
		deleteHandle (id) {
			this.$root.request('diseaseDel', { id }).then((data) => {
				if (data && data.ok === 1) {
					this.$root.elMsg('操作成功');
					this.getData();
				}
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		},
		changeRecommendStatus (id, recommend) {
			this.$root.request('diseaseRecommendUpdate', {
				id,
				recommend
			}).then((data) => {
				if (data && data.ok === 1) {
					this.$root.elMsg('操作成功');
				} else {
					this.$root.elMsg('操作失败', 'error');
				}
			});
		}
	}
};
</script>